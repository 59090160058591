<template>
  <div>
    <el-row class="h-full">
      <el-col :span="24" class="text-right p-1 laptop:pb-2 desktop:pb-4">
        <el-input
          :placeholder="$t('btn.SearchTankNumber')"
          class="Tankinput"
          size="small"
          v-model="Tankinput"
          @keyup.enter.native="Tankinputval(Tankinput)"
        >
          <span slot="suffix" @click="Tankinputval(Tankinput)">
            <svg-icon iconClass="search" />
          </span>
        </el-input>
        <el-button
          v-if="
            userInfo.userType === '1' ||
            userInfo.userType === '0' ||
            userInfo.role === '1'
          "
          v-show="userInfo.role === '1' || userInfo.userType === '0'"
          plain
          style="margin-left: 20px"
          @click="TankBtn"
          size="small"
          >{{ $t("btn.CreateTank") }}</el-button
        >
      </el-col>
    </el-row>
    <div style="padding:20px 0px; background-color: white; border-radius: 10px;">
      <el-row class="rowAllTanks">
        <div class="divAllTanks">{{ $t("common.allTanks") }}</div>
        <el-button
          class="btnSortTanks" size="large" type="info" plain icon="sort-icon el-icon-sort"
          @click="handleSortTankBtnClick"
        />
      </el-row>
      <el-row>
        <el-col :span="24" class="content p-3">
          <div
            v-loading="listLoadig"
            class="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 gap-2"
          >
            <div class="item flex flex-col p-3" v-for="(item, index) in list" :key="index">
              <div class="Tanname flex-none">
                <span :title="item.tankName">{{ item.tankName }}</span>
                <span
                  class="svgs"
                  v-show="userInfo.role === '1' || userInfo.userType === '0'"
                  @click="handleEditClick(item, index)"
                >
                  <svg-icon icon-class="amend" />
                </span>
              </div>
              <div class="truncate grow">
                <p class="Updated">{{ $t("common.SurveyorCompany") }}:</p>
                <p class="time" :title="item.showSname">{{ item.showSname }}</p>
                <p class="Updated">{{ $t("common.CustomerCompany") }}:</p>
                <p class="time" :title="item.showCname">{{ item.showCname }}</p>
              </div>
              <div class="flex-none">
                <p class="Uploaded">{{ $t("btn.CQUploaded") }}:</p>
                <div
                  class="img cursor-pointer"
                  v-if="item.tankFile"
                  @click="handlePdfClick(item)"
                >
                  <span>
                    <svg-icon icon-class="pdf" />
                  </span>
                  <div class="truncate">
                    <p class="name" :title="item.tankFile.originalName">{{ item.tankFile.originalName }}</p>
                    <p class="time">{{ item.tankFile.createTime }}</p>
                  </div>
                </div>
                <div class="Noimg" v-else>No Data</div>
              </div>
              <div class="Tankbut">
                <!-- <el-upload
                  :show-file-list="false"
                  accept=".pdf"
                  class="upload-demo"
                  :action="action"
                  :multiple="true"
                  :headers="headers"
                  :on-progress="handleFileProgress"
                  :on-success="handlesuccess"
                >
                  <el-button size="small" plain>{{
                    $t("btn.UploadCQ")
                  }}</el-button>
                </el-upload> -->
                <el-button @click="handleUploadClick(item)" size="small" plain>{{
                  $t("btn.UploadCQ")
                }}</el-button>
                <!-- <el-button
                  size="small"
                  type="warning"
                  plain
                  @click="AssignList(item, index)"
                  >{{ $t("btn.AssignList") }}</el-button
                > -->
              </div>
            </div>
            <div v-if="listLoadig" style="height: 400px; width: 100%"></div>
          </div>
          <no-data v-if="list.length === 0 && !listLoadig" />
          <div class="flex justify-end w-full">
            <pagination
              v-show="total > 0"
              :total="total"
              :page.sync="pageParams.pageNum"
              :limit.sync="pageParams.pageSize"
              @pagination="getList"
            ></pagination>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- 创建邮箱 -->
    <el-dialog
      width="360px"
      :title="isEdit ? $t('btn.ModifyTank') : $t('btn.CreateTank')"
      :visible.sync="dialogFormVisible"
    >
      <el-form
        ref="form"
        size="small"
        label-position="top"
        :model="form"
        :rules="rules"
      >
        <el-form-item :label="$t('btn.TankName')" prop="tankName">
          <el-input
            size="small"
            v-model="form.tankName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('btn.TankNumber')" prop="tankNumber">
          <el-input size="small" v-model="form.tankNumber"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('common.SurveyorCompany')"
          prop="surveyorCompany"
        >
          <el-select
            value-key="id"
            style="width: 100%"
            v-model="form.slist"
            :multiple="true"
          >
            <el-option
              v-for="item in surveyorCompanyOptions"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('common.CustomerCompany')"
          prop="customerCompany"
        >
          <el-select
            value-key="id"
            style="width: 100%"
            v-model="form.clist"
            :multiple="true"
          >
            <el-option
              v-for="item in customerCompanyOptions"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="small"
          type="primary"
          :loading="saveBtnLoading"
          @click="handleSaveClick"
          >{{ $t("btn.save") }}
        </el-button>
      </div>
    </el-dialog>
    <!-- 分配列表 -->
    <el-dialog
      width="640px"
      :title="$t('btn.AssignList')"
      :visible.sync="AssignListFormVisible"
    >
      <div style="color: #4c565c; font-size: 13px">{{ $t("jm.Assigned") }}</div>
      <el-table
        ref="multipleTable"
        :data="assignedList"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="AssignedSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          prop="companyName"
          :label="$t('jm.CompanyName')"
          width="200"
        />
        <el-table-column
          prop="uenNumber"
          :label="$t('jm.UENNumber')"
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="email"
          :label="$t('table.EmailAddress')"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          :loading="saveBtnLoading"
          size="small"
          @click="AssignedSaveClick"
          >{{ $t("btn.save") }}
        </el-button>
      </div>
      <div style="margin-top: 30px; color: #4c565c; font-size: 13px">
        {{ $t("jm.AllSurveyorCompanies") }}
      </div>
      <el-table
        ref="companyListMultipleTable"
        :data="companyList"
        tooltip-effect="dark"
        style="width: 100%"
        :row-key="getRowKey"
        @selection-change="CompaniesSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          prop="companyName"
          :label="$t('jm.CompanyName')"
          width="200"
        />
        <el-table-column
          prop="uenNumber"
          :label="$t('jm.UENNumber')"
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="email"
          :label="$t('table.EmailAddress')"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
    </el-dialog>
    <input
      type="file"
      ref="inputFile"
      :multiple="true"
      accept=".pdf"
      @change="handleFileChange"
      style="opacity: 0; position: fixed; z-index: -1"
    />
    <pdf-view
      title="Certificate Of Quality"
      :pdfUrl="operationData.src"
      v-model="showPdfView"
    />
  </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon.vue";
import {
  addTanks,
  editTanks,
  getTanksList,
  tanksAddFiles,
  singleleUploadFile,
  allCompanyList,
  companyLink,
} from "@/services/index";
import { mapState } from "vuex";
import { getToken } from "@/utils/auth";
import { getServerUrl } from "@/utils/getServerUrl";
import PdfView from "@/components/common/pdf-view.vue";
import noData from "@/components/common/no-data.vue";
export default {
  components: { SvgIcon, PdfView, noData },
  name: "tank",
  data() {
    return {
      dialogFormVisible: false,
      AssignListFormVisible: false,
      Tankinput: "",
      form: {
        tankName: "",
        tankNumber: "",
        clist: "",
        slist: "",
      },
      rules: {
        tankName: [
          { required: true, message: "Please Input", trigger: "blur" },
        ],
        tankNumber: [
          { required: true, message: "Please Input", trigger: "blur" },
        ],
        clist: [{ required: true, message: "Please Select", trigger: "blur" }],
        slist: [{ required: true, message: "Please Select", trigger: "blur" }],
      },
      // 分配列表数据
      list: [],
      AssignedSelection: [],
      CompaniesSelection: [],
      Nameimg: "",
      isEdit: false,
      saveBtnLoading: false,
      pageParams: {
        pageNum: 1,
        pageSize: 10,
      },
      editData: {
        id: null,
        index: null,
        tankName: "",
        tankNumber: "",
        value: "",
        surveyorCompany: "",
        customerCompany: "",
      },
      total: 0,
      action: process.env.VUE_APP_BASE_API + "/common/uploads", // 上传文件服务器地址
      headers: { Authorization: "Bearer " + getToken() },
      operationData: {
        tankNumber: "",
      },
      assignedList: [],
      companyList: [],
      assignedSelectList: [],
      showPdfView: false,
      selectionChangeLock: false,
      effectSelectCallback: [],
      listLoadig: false,
      surveyorCompanyOptions: [],
      customerCompanyOptions: [],
      isSortAsc: null
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  created() {
    this.getList();
    this.getCompanyList();
  },
  watch: {
    AssignListFormVisible(value) {
      if (!value) {
        this.selectionChangeLock = true;
        this.effectSelectCallback.forEach((fn) => fn());
        this.effectSelectCallback = [];
        this.$nextTick(() => {
          this.selectionChangeLock = false;
        });
      }
    },
  },
  methods: {
    getOptions() {
      this.getCompanyList;
    },
    getCompanyList() {
      // Surveyor Company
      allCompanyList({ role: 6 }).then((res) => {
        this.surveyorCompanyOptions = res.data;
      });
      // Customer Company
      allCompanyList({ role: 4 }).then((res) => {
        this.customerCompanyOptions = res.data;
      });
    },
    getRowKey(row) {
      return row.id;
    },
    // 创建
    TankBtn() {
      this.dialogFormVisible = true;
      this.isEdit = false;
      this.$nextTick(() => {
        for (let key in this.form) {
          this.form[key] = null;
        }
      });
    },
    handlePdfClick(item) {
      if (this.userInfo.role == "1") {
        this.operationData = {
          src: getServerUrl() + item.tankFile.url,
        };
        this.showPdfView = true;
      } else {
        this.showPdfView = false;
      }
    },
    handleSortTankBtnClick () {
      if (this.isSortAsc) {
        this.getList({
          isAsc: 'asc',
          orderByColumn: 'tankName'
        })
      } else {
        this.getList({
          isAsc: 'desc',
          orderByColumn: 'tankName'
        })
      }
      this.isSortAsc = !this.isSortAsc
    },
    getList(params = {}) {
      this.listLoadig = true;
      getTanksList({
        ...this.pageParams,
        ...params,
      })
        .then((res) => {
          this.listLoadig = false;
          this.list = res.rows.map((item) => {
            return {
              ...item,
              assignedList: [],
              showCname: item.clistCompany.length
                ? item.clistCompany.map((i) => i.companyName).join(",")
                : "",
              showSname: item.slistCompany.length
                ? item.slistCompany.map((i) => i.companyName).join(",")
                : "",
            };
          });
          this.total = res.total;
        })
        .finally(() => (this.listLoadig = false));
    },

    handleFileChange(event) {
      let files = event.target.files;
      if (files.length) {
        let arrFiles = [...files];
        let requests = [];
        arrFiles.forEach((file) => {
          requests.push(singleleUploadFile(file));
        });
        Promise.all(requests).then((res) => {
          // 上传附件
          let datas = [];
          res.forEach((item) => {
            datas.push({
              url: item.fileName,
              tankNumber: this.operationData.tankNumber,
              originalName: item.originalFilename,
            });
          });
          tanksAddFiles(datas).then(() => {
            this.$message.success(this.$t("status.success"));
            this.getList();
          });
        });
      }
    },
    getOptionsShowname(type = 0) {
      let list = type === 0 ? this.form.clist : this.form.slist;
      let options =
        type === 0 ? this.customerCompanyOptions : this.surveyorCompanyOptions;
      if (list.length) {
        let result = options.filter((item) => list.includes(item.id));
        return result.map((item) => item.companyName).join(",");
      } else {
        return "";
      }
    },
    handleSaveClick() {
      this.$refs.form.validate((res) => {
        if (res) {
          let request;
          this.saveBtnLoading = true;
          if (this.isEdit) {
            request = editTanks({ id: this.editData.id, ...this.form });
          } else {
            request = addTanks({ ...this.form });
          }
          request
            .then(() => {
              // if (!this.isEdit) {
              //   this.getList();
              // } else {
              //   let target = this.list[this.editData.index];
              //   this.$set(this.list, this.editData.index, {
              //     ...target,
              //     showCname: this.getOptionsShowname(0),
              //     showSname: this.getOptionsShowname(1),
              //     tankName: this.form["tankName"],

              //   });
              // }
              this.getList();
              this.$message.success(this.$t("status.success"));
            })
            .finally(() => {
              this.dialogFormVisible = false;
              this.saveBtnLoading = false;
            });
        }
      });
    },
    // 上传 按钮
    handlesuccess(response, file) {
      this.Nameimg = file.name;
    },
    // AssignList 按钮
    AssignList(item, index) {
      this.AssignListFormVisible = true;
      this.assignedList = [...item.assignedList];
      this.$nextTick(() => {
        item.assignedList.forEach((row) => {
          this.$refs.companyListMultipleTable.toggleRowSelection(row);
        });
        this.effectSelectCallback.push(() => {
          item.assignedList.forEach((row) => {
            this.$refs.companyListMultipleTable.toggleRowSelection(row);
          });
        });
      });

      this.operationData = {
        ...item,
        index,
      };
    },
    // input框
    Tankinputval(value) {
      if (value) {
        this.getList({
          tankNumber: value,
        });
      } else {
        this.getList();
      }
    },
    // Assigned分配
    AssignedSelectionChange(val) {
      this.AssignedSelection = val;
    },
    // All Surveyor Companies
    CompaniesSelectionChange(val) {
      if (this.selectionChangeLock) return;
      this.CompaniesSelection = val;
      this.assignedList = [...val];
      this.list[this.operationData.index].assignedList = [...val];
    },
    // 分配按钮提交
    AssignedSaveClick() {
      this.AssignListFormVisible = false;
      let data = {
        cids: this.assignedList.map((item) => item.id),
        tid: this.operationData.id,
      };
      companyLink(data).then(() => {
        this.$message.success(this.$t("status.success"));
      });
    },
    handleEditClick(item, index) {
      this.isEdit = true;
      this.dialogFormVisible = true;
      this.form = {
        tankName: item.tankName,
        tankNumber: item.tankNumber,
        clist: item.clistCompany.length
          ? item.clistCompany.map((item) => item.cid)
          : [],
        slist: item.slistCompany.length
          ? item.slistCompany.map((item) => item.cid)
          : [],
      };
      this.editData = {
        id: item.id,
        index,
        ...this.form,
      };
    },
    handleUploadClick(item) {
      this.$refs.inputFile.click();
      this.operationData.tankNumber = item.tankNumber;
    },
  },
};
</script>

<style scoped lang="scss">
.Tankinput {
  width: 275px;
  height: 32px;
}

.rowAllTanks {
  box-sizing: border-box;
  padding: 8px 18px 8px 18px;
  height: 64px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  .divAllTanks {
    font-weight: 700;
    font-size: 18px;
    color: #4c565c;
  }
  .btnSortTanks {
    background-color: transparent;
    border: none;
    padding: 12px;
    ::v-deep .sort-icon {
        font-size: 18px;
      }
  }

}

.el-button--info.is-plain:hover {
  color: #bababa;
}
.el-button--info {
  border-color: #bababa;
}
.el-button--info:focus {
  color: #bababa !important;
}

.el-button--warning.is-plain:hover {
  background: #fff;
  color: #008CDB;
  border-color: #008CDB;
}
.el-button--warning {
  border-color: #008CDB;
}
.el-button--warning:focus {
  background: #fff !important;
  color: #008CDB !important;
  border-color: #008CDB;
}

.el-button--default {
  color: #fff;
  background-color: #008CDB;
}
.el-button--default.is-plain:hover {
  color: #fff;
  background: #008CDB;
  border-color: #008CDB;
}
.el-button--default:focus {
  color: #fff !important;
  background: #008CDB !important;
  border-color: #008CDB;
}

.content {
  background: #fcfdff;
  .item {
    background: #fff;
    box-shadow: 0px 4px 4px 2px rgba(196, 196, 196, 0.1);
    border: 1px solid #D5DDED;
    border-radius: 10px;
  }
  .Tanname {
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    color: #4c565c;
    .svgs {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 50%;
      border: 1.5px solid #82889c;
      width: 35px;
      height: 35px;
      font-size: 15px;
    }
  }
  .Updated {
    margin-top: 21px;
    font-size: 12px;
    color: #4c565c;
    font-weight: 400;
    line-height: 14px;
  }
  .time {
    margin-top: 2px;
    font-weight: 700;
    line-height: 23px;
    // height: 23px;
    font-size: 20px;
    color: #4c565c;
    overflow-wrap: break-word;
  }
  .Uploaded {
    margin-top: 26px;
    margin-bottom: 5px;
    font-size: 12px;
    color: #4c565c;
  }
  .img {
    display: flex;
    // justify-content: center;
    align-items: center;
    height: 65px;
    text-align: center;
    margin-bottom: 15px;
    color: #008CDB;
    background-color: #fff7f2;
    p {
      text-align: left;
    }
    .name {
      font-weight: 600;
      font-size: 14px;
    }
    .time {
      font-weight: 400;
      font-size: 12px;
      margin-top: 5px;
    }
    svg {
      margin: 0 10px;
      width: 40px;
      height: 50px;
    }
  }
  .Noimg {
    height: 65px;
    text-align: center;
    line-height: 65px;
    margin-bottom: 15px;
    background-color: #f4f8fb;
    color: #82889c;
    font-size: 12px;
  }
  .Tankbut {
    display: flex;
    // justify-content: space-between;
    justify-content: center;
  }
}
::v-deep {
  .el-input__icon.el-icon-search {
    height: 32px !important;
  }
}
::v-deep .el-dialog__title {
  font-weight: 700;
  font-size: 16px;
  color: #4c565c;
}
::v-deep .el-form-item__label {
  color: $grayColor;
  padding: 0;
}
::v-deep .el-table__cell {
  font-size: 12px;

  color: #82889c;
}
::v-deep .pdfView .el-dialog__body {
  height: 80vh;
  margin-top: 10px;
  overflow-y: scroll;
}
::v-deep .pagination-container {
  background: #fcfdff;
}
::v-deep .el-form-item--small.el-form-item {
  margin-bottom: 15px;
}
::v-deep .el-input__suffix-inner {
  padding-right: 5px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  svg {
    width: 13px;
    height: 13px;
    cursor: pointer;
  }
}
</style>
